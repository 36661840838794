export const initMobileMenu = () => {
    const header = document.querySelector('.header');
    if (!header) return;

    // BURGER-MENU
    const menuBtn = document.querySelector('.menu-btn');
    const menuContent = document.querySelector('.nav-mobile-container');
    const bodyOvf = document.querySelector('html');
    const bgForMenu = document.querySelector('.mobile-bg-shade');
    let menuOpen = false;

    menuBtn.addEventListener('click', () => {
        if (!menuOpen) {
            menuBtn.classList.add('open');
            menuContent.classList.add('active');
            bodyOvf.classList.add('overflow');
            bgForMenu.classList.remove('hidden');
            menuOpen = true;
        } else {
            menuBtn.classList.remove('open');
            menuContent.classList.remove('active');
            bodyOvf.classList.remove('overflow');
            menuOpen = false;
            bgForMenu.classList.add('hidden');
        }
    });
};
