import { initScrollToTop } from './components/initScrollToTop';
import { initJivosite } from './components/initJivosite';
import { initScrollLocker } from './tools/scrollLocker';
import { initLazyLoadFonts } from './components/fonts/initLazyLoadFonts';
import { initMobileMenu } from './components/initMobileMenu';
import { initHashEmail } from './components/initHashEmail';
import { initFunnelHash } from '../../../../Common/Resources/src_front/js/components/initFunnelHash';
import { initPageLoader } from '../../../../Common/Resources/templates/components/PageLoader/initPageLoader';
import { initRippleAnimation } from '../../../../Common/Resources/templates/components/RippleAnimation/initRippleAnimation';
import { initDetectionOrganicTraffic } from '../../../../Common/Resources/src_front/js/components/initDetectionOrganicTraffic';
import { initDetectionReferrerTraffic } from '../../../../Common/Resources/src_front/js/components/initDetectionReferrerTraffic';
import { initChunkPageBeforeCheckout } from './components/initChunkPageBeforeCheckout';
import { initFooterDropdowns } from './components/dropdowns/initFooterDropdowns';
import { initScrollToAnchor } from './components/initScrollToAnchor';
document.addEventListener('DOMContentLoaded', function () {
    initPageLoader();
    initChunkPageBeforeCheckout();
    initLazyLoadFonts(['Roboto:300,400,500,700', 'Poppins:300,400,500,600,700,800,900&display=swap']);
    initDetectionReferrerTraffic();
    initFunnelHash();
    initDetectionOrganicTraffic();
    initFooterDropdowns();
    initScrollLocker();
    initMobileMenu();
    initScrollToTop();
    initHashEmail();
    initRippleAnimation();
    initScrollToAnchor();
    initJivosite();
});

// If the browser has cached the page
window.addEventListener('pageshow', (event) => {
    if (event.persisted) {
        // Hide PageLoader
        setTimeout(function () {
            if (window.pageLoader) {
                window.pageLoader.hide();
            }
        }, 1000);

        // Set current checkbox - price split
        setStyleCheckedCheckbox();
    }

    function setStyleCheckedCheckbox() {
        const radioCards = document.querySelectorAll('[data-plan-radio-card]');
        if (!radioCards.length) return;

        [...radioCards].forEach((radioCard) => {
            radioCard.checked
                ? radioCard.closest('[data-plan-radio-card-label]').classList.add('checked')
                : radioCard.closest('[data-plan-radio-card-label]').classList.remove('checked');
        });
    }
});
