export const scrollTo = (selector, smooth = true, offset) => {
    const target = document.querySelector(selector);
    if (!target) return;
    if (smooth) {
        return window.scrollTo({
            top: target.offsetTop + offset,
            behavior: 'smooth',
        });
    } else {
        return window.scrollTo({
            top: target.offsetTop + offset,
        });
    }
};
