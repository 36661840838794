export const initPageLoader = () => {
    // VARS
    const links = document.querySelectorAll('a');
    const filteredLinks = [...links].filter((link) => {
        if (link.href.indexOf('#') === -1 && link.getAttribute('target') !== '_blank' && link.href) {
            return link;
        }
    });

    // INITS
    window.pageLoader = new PageLoader();

    // LISTENERS
    filteredLinks.forEach((link) => {
        link.addEventListener('click', function () {
            window.pageLoader.show();
        });
    });
};

function PageLoader() {
    // VARS
    const $this = this;
    const loader = document.querySelector('[data-page-loader]');
    const visibleClass = 'page-loader--visible';

    // METHODS
    $this.show = () => {
        loader?.classList.add(visibleClass);
    };

    $this.hide = () => {
        loader?.classList.remove(visibleClass);
    };
}
