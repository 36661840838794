export const initScrollToTop = () => {
    const button = document.querySelector('[data-scroll-top-button]');
    if (!button) return;

    button.addEventListener('click', scrollToTop, false);

    window.addEventListener('scroll', toggleOnScroll, false);
};

function scrollToTop() {
    window.scrollTo({
        top: 0,
        behavior: 'smooth',
    });
}

function toggleOnScroll() {
    const button = document.querySelector('[data-scroll-top-button]');
    if (!button) return;

    window.pageYOffset > 200 ? button.classList.add('is-show') : button.classList.remove('is-show');
}
