import { getSiteName } from '../helper-functions/getSiteName';

export const initDetectionOrganicTraffic = () => {
    // VARS
    const referrer = document.referrer;
    if (!referrer) return;
    const refererLink = new URL(document.referrer);
    const windowSearchParams = new URLSearchParams(window.location.search);
    const utmMedium = windowSearchParams.get('utm_medium');
    const utmSource = windowSearchParams.get('utm_source');
    const utmCampaign = windowSearchParams.get('utm_campaign');
    const gclid = windowSearchParams.get('gclid');
    const sessionStorageSearchURL = sessionStorage.getItem('url');
    const prevSiteName = getSiteName(refererLink.hostname);
    const currentSiteName = getSiteName(window.location.hostname);
    const searchingSystems = ['google', 'bing', 'yahoo', 'yandex', 'baidu', 'duckduckgo'];
    let referrerSiteName = '';

    // INITS
    // 1. Check our website
    if (prevSiteName === currentSiteName) return;

    // 2. Check searching systems
    searchingSystems.forEach((searchingSystem) => {
        if (refererLink.hostname.indexOf(searchingSystem) !== -1) {
            referrerSiteName = searchingSystem;
        }
    });

    if (!referrerSiteName) return;

    // 3. Check advertising params
    if (utmMedium || utmSource || utmCampaign || gclid) return;

    // 4. Change url params and pass the parameters throughout the funnel
    const paramsOrganicTraffic = `utm_medium=organic&utm_source=${referrerSiteName}`;

    if (sessionStorageSearchURL) {
        sessionStorage.setItem('url', `${sessionStorageSearchURL}&${paramsOrganicTraffic}`);
    } else {
        sessionStorage.setItem('url', `?${paramsOrganicTraffic}`);
    }

    const searchUrl = sessionStorage.getItem('url');

    history.pushState({}, '', window.location.origin + window.location.pathname + searchUrl);
};
