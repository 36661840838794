/*
Usage:
1. Add data-animation-ripple to your button.
2. When hovering over the button, it should have a background.
   If it is white, then you need to rewrite the styles, by default Ripple draws white circles.
*/
export function initRippleAnimation() {
    const buttons = document.querySelectorAll('[data-animation-ripple]');

    buttons.forEach((button) => {
        button.addEventListener('click', handleOnClick, false);
    });

    // HANDLERS
    function handleOnClick(event) {
        const { top, left } = getPositionClickInsideButton(event, this);
        const color = this.dataset.animationRipple ? this.dataset.animationRipple : 'white';
        const circle = createCircle(color);

        circle.style.top = top + 'px';
        circle.style.left = left + 'px';
        circle.style.position = 'absolute';

        this.appendChild(circle);

        setTimeout(() => circle.remove(), 500);
    }

    // FUNCTIONS
    function getPositionClickInsideButton(event, element) {
        const clientClickX = event.clientX;
        const clientClickY = event.clientY;
        const viewportOffset = element.getBoundingClientRect();
        const buttonTop = viewportOffset.top;
        const buttonLeft = viewportOffset.left;

        return {
            top: clientClickY - buttonTop,
            left: clientClickX - buttonLeft,
        };
    }

    function createCircle(color) {
        const circle = document.createElement('span');
        circle.classList.add('circle');
        circle.classList.add(color);

        return circle;
    }
}
