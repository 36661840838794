export const initFooterDropdowns = () => {
    if (document.querySelector('.footer')) {
        const disclaimerShow = document.querySelector('#disclaimer-show');
        const disclaimerHide = document.querySelector('#disclaimer-hide');
        const footerDesctiption = document.querySelector('.footer-disclaimer');
        footerDesctiption.classList.toggle('collapsed');
        disclaimerHide.classList.toggle('hidden');

        document.getElementById('disclaimer-watch').onclick = function () {
            footerDesctiption.classList.toggle('collapsed');
            disclaimerShow.classList.toggle('hidden');
            disclaimerHide.classList.toggle('hidden');
        };

        let footerAccordion = {
            init: function () {
                let headers = document.querySelectorAll('.footer-block');
                if (headers.length > 0) {
                    for (let head of headers) {
                        head.addEventListener('click', footerAccordion.select);
                    }
                }
            },

            select: function () {
                this.classList.toggle('active');
            },
        };

        footerAccordion.init('.footer-block');
    }

    let footerLangAccordion = {
        init: function () {
            let headers = document.querySelectorAll('.footer-lang-container');
            if (headers.length > 0) {
                for (let head of headers) {
                    head.addEventListener('click', footerLangAccordion.select);
                }
            }
        },

        select: function () {
            this.classList.toggle('active');
        },
    };

    footerLangAccordion.init('.footer-lang-container');
};
