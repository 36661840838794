import { insertScript } from '../../../../../Common/Resources/src_front/js/helper-functions/insertScript';

export const initChunkPageBeforeCheckout = () => {
    const pricesScript = document.querySelector('[data-prices-script-scr]');
    if (!pricesScript) {
        window.pageLoader.hide();
        return;
    }
    const url = pricesScript.dataset.pricesScriptScr;
    const script = insertScript(url);

    script.addEventListener('load', function () {
        window.pageLoader.hide();
    });

    script.addEventListener('error', function () {
        window.pageLoader.hide();
    });
};
