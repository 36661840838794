import { setCookie } from '../../../../../Common/Resources/src_front/js/helper-functions/setCookie';
import { getCookie } from '../../../../../Common/Resources/src_front/js/helper-functions/getCookie';

export function initJivosite() {
    // INITS
    const jivoChatHashCookie = Boolean(getCookie('jivoChatHash'));
    const searchParams = window.location.search;
    const hoAffId = getParamValue(searchParams, 'ho_aff_id') || getParamValue(searchParams, 'aff_id');
    const pageNowPath = window.location.pathname;
    const chatAiAffiliatePages = ['/prices.html'];
    const showChatAi = chatAiAffiliatePages.some((page) => pageNowPath.endsWith(page));
    const srcWidget = hoAffId === 'ho_1556' || showChatAi ? '0KNeW49GmT' : '9CzJn8ErCh';
    const srcChat = `//code.jivosite.com/widget/${srcWidget}`;
    const userLocale = document.querySelector('[data-locale]')?.dataset.locale;

    if (hoAffId === 'ho_1505' || (userLocale && userLocale === 'IN')) {
        return false;
    } else {
        if (jivoChatHashCookie) {
            appendChat();
            listenerChat();
            setJivoChatDataToCookie();
        } else {
            setTimeout(function () {
                appendChat();
                listenerChat();
            }, 5000);
        }
    }

    // FUNCTIONS
    function appendChat() {
        const headID = document.getElementsByTagName('head')[0];
        const newScript = document.createElement('script');
        newScript.type = 'text/javascript';
        newScript.src = srcChat;
        newScript.setAttribute('defer', '');
        headID.appendChild(newScript);
    }

    function listenerChat() {
        const listenLoadChat = setInterval(timerListenLoadChat, 1000);

        function timerListenLoadChat() {
            if (Boolean(window.jivo_api)) {
                window.jivo_onMessageSent = function jivo_onMessageSent() {
                    const cookieOptions = {
                        path: '/',
                    };
                    setCookie('jivoChatHash', srcWidget, cookieOptions);
                };

                window.jivo_api.getVisitorNumber(function (error, visitorNumber) {
                    const cookieOptions = {
                        path: '/',
                    };
                    setCookie('jivoChatVisitor', visitorNumber, cookieOptions);
                });

                stopListenLoadChat();
                jivo_onLoadCallback();
            }
        }

        function stopListenLoadChat() {
            clearInterval(listenLoadChat);
        }
    }

    function appendSessionToCookies(cookieName, value) {
        const cookieOptions = {
            path: '/',
        };

        setCookie(cookieName, value, cookieOptions);
    }

    function setJivoChatDataToCookie() {
        const submits = document.querySelectorAll('[data-jivo-chat-cookie]');
        submits.forEach((submit) => {
            submit.addEventListener('click', function (e) {
                const hashClient = `jv_store_${srcWidget}_client`;
                const hashApp = `jv_store_${srcWidget}_app`;
                const jv_store_client = localStorage.getItem(hashClient);
                const jv_store_app = localStorage.getItem(hashApp);

                if (!Boolean(jv_store_client) || !Boolean(jv_store_app)) return;

                const jivoChatSession = {
                    [hashClient]: JSON.parse(jv_store_client),
                    [hashApp]: JSON.parse(jv_store_app),
                };

                appendSessionToCookies('jivoChatSession', JSON.stringify(jivoChatSession));
            });
        });
    }

    function jivo_onLoadCallback() {
        window.jivo_api.setContactInfo({
            name: document.querySelector('[data-email]').getAttribute('data-email'),
            email: document.querySelector('[data-email]').getAttribute('data-email'),
        });
        window.jivo_api.setCustomData([
            {
                title: 'Brand',
                content: document.querySelector('[data-brand]').getAttribute('data-brand'),
            },
        ]);
    }

    function getParamValue(searchParams, paramName) {
        const params = new URLSearchParams(searchParams);
        return params.get(paramName);
    }
}
